import styled from "styled-components"

const Styles = styled.div`
  padding-top: 3rem;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
`

export default Styles
