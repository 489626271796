import React, { useState } from "react"
import Styled from "./styles"
import fotoTemp from "../../../images/profile_page/icon-akun.png"
import StarRatings from "react-star-ratings"
import moment from "moment"
import Reply from "./reply"

const ReviewCard = ({ review, user, canReply = false }) => {
  const [reply, setReply] = useState(review?.course_rate_reply)

  const updateReply = async data => {
    setReply(data)
  }

  return (
    <Styled>
      <img
        src={review.user.profile_picture || fotoTemp}
        alt={review.user.username}
      />
      <div>
        <div className="top">
          {review.user.display_name || review.user.username}
          <span className="date">{moment(review.created_at).fromNow()}</span>
        </div>
        <div>
          <StarRatings
            rating={parseFloat(review.rate)}
            starRatedColor="#FF9529"
            starEmptyColor="#9ca3af"
            starDimension="1rem"
            starSpacing="1px"
          />
        </div>
        <div className="content">{review.comment}</div>

        {reply?.id && (
          <div className="reply">
            <div className="top">
              {user.display_name || user.username}{" "}
              <span className="date">{moment(reply.created_at).fromNow()}</span>
            </div>
            <div className="content">{reply.content}</div>
          </div>
        )}

        {canReply && (
          <Reply
            reply={reply}
            reviewId={review?.id}
            updateReply={updateReply}
          />
        )}
      </div>
    </Styled>
  )
}

export default ReviewCard
