import styled from "styled-components"

const Styled = styled.div`
  margin: 0rem 0;
  padding: 1rem 0;
  display: flex;
  gap: 1rem;

  & > div {
    width: 100%;
  }

  img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }

  .top {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;

    span {
      margin-left: 0.5rem;
    }
  }

  .date {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
  }

  .content {
    padding: 0.5rem 0;
  }

  .reply {
    padding: 0.5rem 1rem;
    padding-top: 0.75rem;
    background: #f4f4f4;
  }
`

export default Styled
