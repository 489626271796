import axios from "axios"
import React, { useEffect, useRef, useState } from "react"
import DashboardTutorComponent from ".."
import {
  getCourseReviewsByCourseId,
  getCoursesByTutor,
} from "../../../services/course"
import useOnScreen from "../../Hooks/useIntersection"
import ReviewCard from "../../Reusable/ReviewCard"
import Spinner from "../../Reusable/Spinner"

import Styles from "./styles"

const Course = ({ user }) => {
  const [courses, setCourses] = useState([])
  const [reviews, setReviews] = useState([])
  const [loading, setLoading] = useState(true)
  const [next, setNext] = useState(null)
  const elementRef = useRef(null)
  const isOnScreen = useOnScreen(elementRef)

  useEffect(() => {
    if (isOnScreen && next) {
      setLoading(true)
      axios.get(next).then(resp => {
        setReviews([...reviews, ...resp.data?.results])
        setNext(resp?.data?.next)
        setLoading(false)
      })
    }
  }, [isOnScreen])

  useEffect(() => {
    getCoursesByTutor(user.id).then(resp => {
      if (resp?.data?.results) {
        setCourses(resp?.data?.results)
      }
    })
  }, [])

  useEffect(() => {
    if (courses[0]?.id) {
      getCourseReviewsByCourseId(courses[0]?.id).then(resp => {
        if (resp?.data?.results) {
          setReviews(resp?.data?.results)
          setNext(resp?.data?.next)
        }
      })
    }
  }, [courses])

  return (
    <DashboardTutorComponent chosen={2}>
      <Styles>
        <h3>Review Kursus</h3>
        <p>{courses?.length > 0 && courses[0]?.title}</p>
        <div>
          {reviews.map((review, index) => (
            <ReviewCard
              review={review}
              key={index}
              canReply={true}
              user={user}
            />
          ))}

          {loading && (
            <div
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner />
            </div>
          )}

          <div ref={elementRef}></div>
        </div>
      </Styles>
    </DashboardTutorComponent>
  )
}

export default Course
