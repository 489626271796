import React from "react"
import * as sessionSelectors from "../../../redux/slices/session/selectors"
import { connect } from "react-redux"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Course from "../../../components/DashboardTutor/Course"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const DashboardTutorCoursePage = ({ user }) => {
  return (
    <Layout>
      <SEO title="Course" />
      <Course user={user} />
    </Layout>
  )
}

export default withConnect(DashboardTutorCoursePage)
