import { BsReply } from "@react-icons/all-files/bs/BsReply"
import { BsPencil } from "@react-icons/all-files/bs/BsPencil"
import { BsTrash } from "@react-icons/all-files/bs/BsTrash"
import React, { useState } from "react"
import styled from "styled-components"
import TextArea from "../Input/textarea"
import Button from "../Button"
import { toast } from "react-toastify"
import {
  deleteCourseReviewReply,
  submitCourseReviewReply,
  updateCourseReviewReply,
} from "../../../services/course"

const Styled = styled.div`
  width: 100%;
  max-width: 480px;

  .btn-act {
    border: none;
    background: white;
    font-size: 0.875rem;
    color: #4b5563;
    font-weight: 500;
  }
`

const Reply = ({ reply, updateReply, reviewId }) => {
  const [active, setActive] = useState(false)
  const [inputField, setInputField] = useState(
    reply?.content || "Terima kasih telah memberikan review."
  )
  const [loading, setLoading] = useState(false)

  const success = data => {
    updateReply(data)
    setActive(false)
    toast.success("Berhasil membalas review")
  }

  const failed = () => {
    toast.error("Terjadi kesalahan")
  }

  const update = async () => {
    const resp = await updateCourseReviewReply(reply?.id, {
      content: inputField,
    })
    if (resp?.status === 200) success(resp.data)
    else failed()
  }

  const submit = async () => {
    const resp = await submitCourseReviewReply({
      content: inputField,
      course_rate: reviewId,
    })
    if (resp?.status === 201) success(resp.data)
    else failed()
  }

  const submitHandler = async () => {
    setLoading(true)
    if (reply?.id) update()
    else submit()
    setLoading(false)
  }

  const deleteHandler = async () => {
    const resp = await deleteCourseReviewReply(reply.id)
    if (resp?.status === 204) {
      updateReply(null)
      toast.success("Berhasil menghapus balasan")
    } else {
      toast.error("Terjadi kesalahan")
    }
  }

  return (
    <Styled>
      {!reply ? (
        <button className="btn-act" onClick={() => setActive(true)}>
          <BsReply /> balas
        </button>
      ) : (
        <span>
          <button className="btn-act" onClick={() => setActive(true)}>
            <BsPencil /> ubah
          </button>
          <button className="btn-act" onClick={deleteHandler}>
            <BsTrash /> hapus
          </button>
        </span>
      )}

      {active && (
        <div>
          <TextArea
            name="isi"
            placeholder="Tuliskan Balasan"
            label=""
            value={inputField}
            onChange={e => setInputField(e.target.value)}
            rows={3}
          />
          <Button
            disabled={!inputField}
            className="small"
            onClick={submitHandler}
          >
            {loading ? "Submitting..." : "Submit"}
          </Button>
          <Button onClick={() => setActive(false)} className="secondary small">
            Batal
          </Button>
        </div>
      )}
    </Styled>
  )
}

export default Reply
